<template>
  <div class="guide_page">
    <titleComponent title="顾问咨询" titleEnglish="ABOUT CONSULT"/>
    <div class="block">
      <img  @click="tab(index)" preview="0" preview-text="" v-for="(item,index) in imgList" :key="item" :src="item" alt="">
    </div>
  </div>
</template>

<script>
import titleComponent from '@/components/home/title.vue'
export default {
  name: 'Guide',
  components:{
    titleComponent
  },
  data(){
    return {
      imgList:[],
      isShow:false,
    }
  },
  created(){
    for(let i=1;i<43;i++){
      let num = (Array(4).join("0") + i).slice(-4);
      this.imgList.push(require('@/assets/img/home/guide/'+ num +'.jpg'))
    }
  },
  methods:{
    tab(val){
      this.isShow = !this.isShow
      console.log(val)
      this.$refs.mySwiper.slideTo(0, 1000, false);
    }
  }
}
</script>

<style lang="scss" scoped>
.guide_page{
  width:1200px;
  margin: 0 auto;
  .block{
    @include flex(row,space-around,center);
    flex-wrap: wrap;
    img{
      box-sizing: border-box;
      width: 200px;
      margin: 0 20px 40px 0;
    }
  }
}
</style>
